import vue from "../../main.js";
import axios from "axios";
import { CacheTool } from "@js/cache-tool.js";

export function exportResult(params,fileName,callback) {
    const instance = axios.create({
        baseURL: process.env.VUE_APP_API_URI_NEW,
        responseType: "blob",
        headers: {
            token: CacheTool.getLoginInfo().token,
            system:1
        },
    });
    instance
        .post("v2/flat/sleepAssessment/downloadAssessment", params)
        .then(async (res) => {
            
            try {
                const dataSize = res.data.size
                if (dataSize < 200) { //报错返回JSON字符串
                    const dataJSON = await res.data.text();
                    const dataModel = JSON.parse(dataJSON)
                    throw dataModel.msg
                } else {
                    let blob = new Blob([res.data], {
                        type: "applicatoin/octet-stream",
                    });
                    // let _fileName = res.headers["content-disposition"].split("=")[1];
                    // let _fileName = params.fileName;
                    //创建link
                    const link = document.createElement("a");
                    link.style.display = "none";
                    const url = window.URL || window.webkitURL || window.moxURL; //兼容不同浏览器的URL对象
                    link.href = url.createObjectURL(blob);
                    link.setAttribute(
                        "download",
                        fileName
                    );
                    document.body.appendChild(link);

                    if (callback) {
                        callback(true);
                      }
                    link.click();
                    //销毁url对象
                    setTimeout(() => {
                        document.body.removeChild(link);
                        url.revokeObjectURL(link.href);
                    }, 1000);

                }
            } catch (error) {
                console.log('catch',error)
                vue.$toast.showRed(`下载的文件出错：${error}`);
            }
        })
        .catch((error) => {
            vue.$toast.showRed(error);
        });
}
