<template>
  <div class="screening-questionnaire">
    <screening-task class="screening-questionnaire-task" @selectID-changed="handleSelectIDChanged" :editCount="editCount" :deleteCount="deleteCount">

    </screening-task>

    <screening-content class="screening-questionnaire-content"  :selectID="selectID" @edit="onEditSucceed" @delete="onDeleteSucceed">

    </screening-content>
  </div>
</template>

<script>
import ScreeningTask from "@c/screening-questionnaire/screening-task/screening-task.vue";
import ScreeningContent from "@c/screening-questionnaire/screening-content/screening-content.vue";
export default {
  components: {
    ScreeningTask,
    ScreeningContent
  },
  data(){
    return{
      selectID: null,
      editCount:0,
      deleteCount:0,
    };
  },
  methods: {
    handleSelectIDChanged(selectID) {
      this.selectID = selectID
    },
    onEditSucceed(value){
      this.editCount = value
    },
    onDeleteSucceed(value){
      this.deleteCount = value
    }
  },


};
</script>

<style lang="scss" scoped>
@import "@css/style.scss";

.screening-questionnaire {
  //   background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;

  &-task {
    margin-right: rem(20);
    min-width: rem(527);
  }

  &-content {
    margin-left: auto;
  }
}
</style>