<template>
    <div class="screening-statistics-dialog">
        <dialog-header class="screening-statistics-dialog-header" v-model="headerTitle"
            @close="onDialogClose"></dialog-header>
        <div class="screening-statistics-dialog-content">
            <div class="screening-statistics-dialog-content-information">
                <div class="screening-statistics-dialog-content-information-base-title">
                    <span class="screening-statistics-dialog-content-information-base-title-count">总人次</span>
                    <span class="screening-statistics-dialog-content-information-base-title-task">任务问卷</span>
                    <span class="screening-statistics-dialog-content-information-base-title-time">起止时间</span>
                </div>

                <div class="screening-statistics-dialog-content-information-base-result">
                    <span class="screening-statistics-dialog-content-information-base-result-count">{{ this.personCount
                    }}人</span>
                    <span class="screening-statistics-dialog-content-information-base-result-task">
                        <div class="screening-statistics-dialog-content-information-base-result-task-content">{{
                            this.taskQuestionnaire }}</div>
                        <div class="screening-statistics-dialog-content-information-base-result-task-count">共{{
                            this.taskCount }}个</div>
                    </span>
                    <span class="screening-statistics-dialog-content-information-base-result-time">{{ this.start2endDate
                    }}</span>
                </div>
                <div class="screening-statistics-dialog-content-information-chart">
                    <div class="screening-statistics-dialog-content-information-chart-title">性别统计</div>
                    <div class="screening-statistics-dialog-content-information-chart-sex-chart" ref="sexChart"
                        style="width: 385px;height: 246px;padding: 0;margin: 0;border: 0;margin-left: 8%;"></div>
                </div>
            </div>
            <div class="screening-statistics-dialog-content-age-time">
                <div class="screening-statistics-dialog-content-age-time-age">
                    <div class="screening-statistics-dialog-content-age-time-age-title">年龄分布</div>
                    <div class="screening-statistics-dialog-content-age-time-age-legend">人次</div>
                    <div class="screening-statistics-dialog-content-age-time-age-chart" ref="ageChart"
                        style="width: 438px;height: 216px;padding: 0;margin: 0;border: 0;"></div>
                </div>
                <div class="screening-statistics-dialog-content-age-time-time">
                    <div class="screening-statistics-dialog-content-age-time-time-title">时间统计</div>
                    <div class="screening-statistics-dialog-content-age-time-time-legend">人次</div>
                    <div class="screening-statistics-dialog-content-age-time-time-chart" ref="timeChart"
                        style="width: 468px;height: 216px;padding: 0;margin: 0;border: 0;"></div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import dialogHeader from "@c/screening-questionnaire/screening-dialog/screening-dialog-header.vue"
import * as echarts from 'echarts'//引入echarts核心模块
import { DateTool } from "@js/date-tool.js";
export default {
    props: {
        value: String,
        taskID: Number,
        count: Number,
    },
    components: {
        dialogHeader,

    },
    data() {
        return {
            headerTitle: this.value,
            dialogVisible: this.visibility,
            personCount: '--',//总人次
            taskQuestionnaire: '--',//任务名称
            taskCount: 0,//任务数量
            start2endDate: null,//起止时间
            sexChart: null,
            manNum: 0,
            femaleNum: 0,
            ageChart: null,
            ageArray: [],
            ageYmaxValue: 0,
            timeChart: null,
            timeChartScale: 0,
            timeChartxAxisData: [],
            timeChartyAxisData: [],
            timeChartMaxY: 0,
            radius: '0%',


        };
    },
    methods: {

        onDialogClose(value) {
            this.$emit('close', value);
        },

        initSexChart: function () {
            if (this.femaleNum === 0 && this.manNum === 0) {
                this.radius = '0%'
            } else {
                this.radius = '80%'
            }

            let chartDom = this.$refs.sexChart; // 获取图表容器

            if (this.sexChart) {
                // 清空之前的图表数据
                this.sexChart.clear();
            } else {
                this.sexChart = echarts.init(chartDom); // 初始化图表实例
            }


            const option = {
                backgroundColor: 'transparent',
                title: { // 无数据时占位用
                    show: this.manNum === 0 && this.femaleNum === 0, // 判断有没有数据，没有则show为true
                    textStyle: {
                        color: '#ccc',
                        fontSize: 18,
                    },
                    text: "暂无数据",
                    left: "center",
                    top: "center"
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                       
                        if (params.data.name === '女') {
                            return params.marker + params.data.name + '性<br>' + '<div style="text-indent: 1em">' + params.value + '人  ' + params.percent + '%';
                        } else {
                            return params.marker + params.data.name + '性<br>' + '<div style="text-indent: 1em">' + params.value + '人  ' + params.percent + '%';
                        }
                    }
                },
                grid: {
                    show: false,
                    containLabel: true,
                    left: '0%',
                    right: '0%',
                    top: '0%',
                    bottom: '0%'
                },
                series: [
                    {
                        name: '性别',
                        type: 'pie',
                        radius: this.radius,
                        data: [
                            { value: this.femaleNum, name: '女', label: { color: "#F45C50" } },
                            { value: this.manNum, name: '男', label: { color: "#2051BD" } }
                        ],
                        itemStyle: { // 设置男女的颜色
                            color: function (params) {
                                // 根据名称返回不同的颜色，例如：女性为红色，男性为蓝色
                                if (params.data.name === '女') {
                                    return '#F45C50';
                                } else {

                                    return '#2051BD';
                                }
                            },

                            borderWidth: 0
                        },
                        label: {
                            show: this.femaleNum !== 0 && this.manNum !== 0,
                            formatter: function (params) {

                                if (params.data.name === '女') {
                                    return params.data.name + '性：' + params.value + '人';
                                } else {
                                    return params.data.name + '性：' + params.value + '人';
                                }
                            }

                        },


                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },

                    }
                ]
            };
            this.sexChart.setOption(option); // 设置图表选项

        },

        initAgeChart: function () {
            let chartDom = this.$refs.ageChart; // 获取图表容器

            if (this.ageChart) {
                this.ageChart.clear();
            } else {
                this.ageChart = echarts.init(chartDom); // 初始化图表实例
            }
            const option = {
                dataset: [
                    {
                        dimensions: ['age', 'personNum'],
                        // source: [
                        //     ['6岁', 21],
                        //     ['12岁', 23],
                        //     ['18岁', 20],
                        //     ['21岁', 14],
                        //     ['25岁', 5],
                        //     ['26岁', 10],
                        //     ['27岁', 28],
                        // ]
                        source: this.ageArray
                    },
                    {
                        transform: {
                            type: 'sort',
                            config: { dimension: 'personNum', order: 'desc' }
                        }
                    }
                ],
                title: { // 无数据时占位用
                    show: this.ageArray.length === 0, // 判断有没有数据，没有则show为true
                    textStyle: {
                        color: '#ccc',
                        fontSize: 18,
                    },
                    text: "暂无数据",
                    left: "center",
                    top: "center"
                },

                grid: {
                    show: false,
                    containLabel: false,
                    left: '6%',
                    right: '0%',
                    top: '8%',
                    bottom: '10%'
                },
                xAxis: {
                    show: this.ageArray.length != 0,
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: "#000000" // 设置x轴的颜色为灰色
                        }
                    }
                },
                yAxis: {
                    // show: this.ageArray.length === 0, // 显示 y 轴
                    show: this.ageArray.length != 0,
                    max: this.ageYmaxValue,
                    splitLine: { show: false }, // 去掉网格线
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#000000" // 设置x轴的颜色为灰色
                        }
                    },
                    axisTick: {
                        show: false
                    },

                },
                series: {
                    type: 'bar',

                    encode: { x: 'age', y: 'personNum' },
                    // datasetIndex: 1,


                },
                tooltip: {
                    show: true,
                    formatter: (params) => { // 自定义格式化函数
                        const xValue = params.name; // x轴对应的值
                        const yValue = params.value[1]; // y轴对应的值
                        return `数量：${yValue}人次<br/>年龄：${xValue}`; // 返回自定义的内容
                    },
                },
            };
            this.ageChart.setOption(option); // 设置图表选项

        },

        initTimeChart: function () {
            let chartDom = this.$refs.timeChart; // 获取图表容器

            if (this.timeChart) {
                this.timeChart.clear();
            } else {
                this.timeChart = echarts.init(chartDom); // 初始化图表实例
            }

            const option = {
                // 配置图表选项
                color: '#1C53BA',//系列柱颜色
                title: { // 无数据时占位用
                    show: this.timeChartyAxisData.length === 0, // 判断有没有数据，没有则show为true
                    textStyle: {
                        color: '#ccc',
                        fontSize: 18,
                    },
                    text: "暂无数据",
                    left: "center",
                    top: "center"
                },

                xAxis: { //x轴
                    show: this.timeChartyAxisData.length != 0,
                    splitLine: { show: false },
                    boundaryGap: false,
                    data: this.timeChartxAxisData,

                    axisLine: {
                        lineStyle: {
                            color: '#262626',
                        }
                    },
                    axisLabel: {
                        showMaxLabel: true,
                        interval: this.timeChartScale, //间隔
                        fontSize: 12,
                        showMaxLabel: true, // 展示最大值
                        showMinLabel: true, // 展示最小值
                        // align:'center',
                        padding: [5, 0, 0, 0],// // 通过设置 padding 属性来控制标签的位置

                        formatter: (value, index) => {
                            if (this.timeChartxAxisData.length > 1) {
                                if (index === 0) {
                                    return '                 ' + value;
                                }
                                if (index === this.timeChartxAxisData.length - 1) {
                                    return value + '                 ';
                                }
                            }
                            return value;
                        }

                    }

                },
                grid: {
                    show: false,
                    containLabel: false,
                    left: '6%',
                    right: '6%',
                    top: '8%',
                    bottom: '11%'
                },
                yAxis: {
                    show: this.timeChartyAxisData.length != 0,
                    axisTick: { show: true },
                    max: this.timeChartMaxY,
                    splitLine: { show: false },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#262626',

                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        fontSize: 12,
                    }
                },//y轴
                // 提示
                tooltip: {
                    show: this.timeChartyAxisData.length != 0,
                    trigger: 'axis',
                    formatter: function (params) {
                        var value = params[0].value;
                        var date = params[0].axisValue;
                        return '数量：' + value + '人次' + '<br>' + '日期：' + date;
                    },
                    textStyle: {
                        color: '#2051BD',
                        fontSize: 12
                    },
                },
                series: [ //
                    {
                        name: '人次',
                        type: 'line',//图表类型
                        data: this.timeChartyAxisData, //图表数据
                        symbol: 'none', // 不显示小圆点
                        itemStyle: {
                            color: '#2051BD',
                            borderWidth: 2,
                            borderColor: '#2051BD'
                        },
                        label: {
                            show: false,
                            position: 'top'
                        }

                    }
                ]
            };
            this.timeChart.setOption(option);
        },

        // method
        async getstatisticalAnalysis() {
            this.manNum = 0
            this.femaleNum = 0
            const params = {
                "id": this.taskID,
            };
            try {
                const data = await this.$api.getstatisticalAnalysis(params);
                this.personCount = data.num
                this.taskQuestionnaire = data.taskQuestionnaire.join(',');
                if (this.taskQuestionnaire.length > 58) {
                    this.taskQuestionnaire = this.taskQuestionnaire.slice(0, 58) + '...';
                };
                this.taskCount = data.taskQuestionnaire.length;
                this.start2endDate = data.startTime + '~' + data.endTime;
              
                this.ageArray = [];

                if (data.genderStatistical !== null) {
                  
                    if (data.genderStatistical[0].keyName === '男') {
                        this.manNum = data.genderStatistical[0].num
                    } else {
                        this.manNum = data.genderStatistical[0].num
                    }

                    if (data.genderStatistical[1].keyName === '女') {
                        this.femaleNum = data.genderStatistical[1].num
                    } else {
                        this.femaleNum = data.genderStatistical[1].num
                    }
                  
                  
                }

           

                let max = 0;
                if (data.ageDistribution !== null) {
                    data.ageDistribution.forEach(item => {
                        if (item.num > max) {
                            max = item.num;
                        }
                    });
                }


                // 计算y轴最大值

                if (max <= 10) {
                    this.ageYmaxValue = 10;
                } else {
                    this.ageYmaxValue = Math.ceil(max / 10) * 10;
                }

                // 使用 map 方法转换数据格式
                if (data.ageDistribution !== null) {
                    this.ageArray = data.ageDistribution.map(item => [item.keyName + '岁', item.num]);
                }


                this.timeChartxAxisData = []
                this.timeChartyAxisData = []

                if (data.timeStatistics !== null) {
                    for (const timeStatistic of data.timeStatistics) {
                        // this.xAxisData.push(timeStatistic.time);
                        this.timeChartyAxisData.push(timeStatistic.num);
                        // this.timeChartxAxisData.push(timeStatistic.keyName)

                    }
                }

                const timeChartMaxNum = Math.max(...this.timeChartyAxisData);
                let timeChartMaxY = 0;
                if (timeChartMaxNum === 0) {
                    timeChartMaxY = 10;
                } else {
                    timeChartMaxY = Math.ceil(timeChartMaxNum / 10) * 10;
                    if (timeChartMaxY === timeChartMaxNum) {
                        timeChartMaxY += 10;
                    }

                }
                this.timeChartMaxY = timeChartMaxY;
                // this.timeChartScale = data.endTime - data.startTime
                const endTime = new Date(data.endTime.replace(/年|月/g, '/').replace(/日/g, '')); // 转换时间格式
                const startTime = new Date(data.startTime.replace(/年|月/g, '/').replace(/日/g, '')); // 转换时间格式
                const diffDays = (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60 * 24) + 1; // 计算时间间隔
                const startTimeTemp = startTime.getTime() / 1000


                if (diffDays > 1) {
                    for (let i = 0; i <= diffDays - 1; i++) {
                        const date = DateTool.timestampToStr(startTimeTemp + i * 24 * 60 * 60, "Y-M-D");
                        this.timeChartxAxisData.push(date);
                    }
                    if (diffDays > 2) {
                        this.timeChartScale = diffDays - 2
                    } else {
                        this.timeChartScale = 1
                    }
                } else {
                    const date = DateTool.timestampToStr(startTimeTemp, "Y-M-D");
                    this.timeChartxAxisData.push(date);
                }

                this.initSexChart();
                this.initAgeChart();
                this.initTimeChart();


            } catch (error) {
                console.log("统计分析错误",error);
                this.$toast.showRed(error);
            }
        },



    },

    watch: {
        taskID(newVal) {

            this.getstatisticalAnalysis();

        },
        count(newVal) {
          
            this.getstatisticalAnalysis();
        }
    },
    mounted() {

        this.getstatisticalAnalysis();


    },

}


</script>

<style lang="scss" scoped>
@import "@css/style.scss";

.screening-statistics-dialog {
    width: 100%;
    min-width: rem(976);
    height: rem(661);

    display: flex;
    flex-direction: column;

    background: #FFFFFF;

    border-radius: 2px;

    &-header {
        width: 100%;
        height: rem(57);
    }

    &-content {
        display: flex;
        flex-direction: column;
        padding: rem(20) rem(0) rem(20) rem(45);

        &-information {
            display: flex;
            position: relative;

            &-base-title {
                font-size: rem(14);
                color: #888888;
                font-weight: 400;
                display: flex;
                flex-direction: column;

                &-task {
                    margin-top: rem(40);

                }

                &-time {
                    margin-top: rem(40);
                }
            }

            &-base-result {
                margin-left: rem(20);
                font-size: rem(14);
                font-weight: 400;
                color: black;
                display: flex;
                flex-direction: column;

                &-task {
                    margin-top: rem(40);
                    display: flex;

                    &-content {
                        max-width: rem(336);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &-time {
                    margin-top: rem(40);
                }

            }

            &-chart {
                position: absolute;
                margin-left: 51%;

                font-size: rem(16);
                font-weight: 400;
                color: black;




            }
        }

        &-age-time {
            margin-top: rem(138);
            font-size: rem(16);
            color: black;
            font-weight: 400;
            display: flex;
            position: relative;

            &-age {
                display: flex;
                flex-direction: column;

                &-legend {

                    margin-top: rem(10);
                    font-size: rem(12);
                    color: black;
                }
            }

            &-time {
                position: absolute;
                margin-left: 51%;

                display: flex;
                flex-direction: column;

                &-legend {

                    margin-top: rem(10);
                    font-size: rem(12);
                    color: black;
                }
            }
        }

    }
}
</style>
