<template>
    <div class="screening-add-task-dialog-header">
        <div class="screening-add-task-dialog-header-content">
            <div class="screening-add-task-dialog-header-content-title">
                {{ headerTitle }}
            </div>
            <div class="screening-add-task-dialog-header-content-close">
                <img src="@imgs/screening-questionnaire-dialog-close.png" alt="关闭dialog" @click="onClose">
            </div>
        </div>

        <div class="screening-add-task-dialog-header-line"></div>
    </div>
</template>

<script>

export default {
    props: {
        value: String,
    },
    data() {
        return {
            headerTitle: this.value
        };
    },
    methods: {
        onClose: function () {
            this.$emit('close', false);
        }
    },




}
</script>

<style lang="scss" scoped>
@import "@css/style.scss";

.screening-add-task-dialog-header {
    width: 100%;
    height: rem(55);
    display: flex;
    flex-direction: column;
    font-size: rem(16);
    font-family: "microsoft-yahei";
    font-weight: 500;

    &-content {
        height: rem(52);
        display: flex;
        justify-content: space-between; // 将子元素分别放置在容器两端

        &-title {
            color: #666666;
            margin-left: rem(24);
            margin-top: rem(17);
        }

        &-close {
            margin-right: rem(24);
            margin-top: rem(20);

            img {
                cursor: pointer;
                width: rem(16);
                height: rem(16);
            }
        }

    }

    &-line {
        width: calc(100% - rem(2));
        border: rem(1) solid #E8E8E8;
        opacity: 1;

    }

}
</style>