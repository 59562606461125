<template>
    <div class="screening-task-content">
        <div class="screening-task-content-task-name-date">
            <span class="screening-task-content-task-name-date-task-symbol">*</span>
            <span class="screening-task-content-task-name-date-task-title">任务名称：</span>
            <el-input maxlength="30" class="screening-task-content-task-name-date-task-input" placeholder="请输入"
                v-model="taskName"></el-input>
            <div class="screening-task-content-task-name-date-date">
                <span class="screening-task-content-task-name-date-date-symbol">*</span>
                <span class="screening-task-content-task-name-date-date-title">起止时间：</span>
                <el-date-picker v-model="startDate" class="screening-task-content-task-name-date-date-datepicker"
                    type="date" placeholder="请选择" @click="onStartDateClick" :show-close="false" />
                <span class="screening-task-content-task-name-date-date-blank">-</span>
                <el-date-picker v-model="endDate" class="screening-task-content-task-name-date-date-datepicker"
                    type="date" placeholder="请选择" />
            </div>

        </div>
        <div class="screening-task-content-task-select">
            <span class="screening-task-content-task-name-date-task-symbol">*</span>
            <span class="screening-task-content-task-name-date-task-title">问卷类型：</span>
            <div class="screening-task-content-task-select-question-type">
                <el-select class="screening-task-content-task-select-question-type-select" v-model="selectedValues"
                    ref="select" @change="updateSelectedTags" @remove-tag="removeTag" placeholder="请选择"
                    :popper-append-to-body="false" multiple>

                    <el-option class="screening-task-content-task-select-select-question-type-option"
                        v-for="item in options" :key="item.value" :label="item.label" :value="item.value">

                    </el-option>

                </el-select>
                <div class="screening-task-content-task-select-question-type-selected-tags" @click="handleSelectClick">
                    <span class="screening-task-content-task-select-question-type-selected-tags-content">{{ selectedTags
                        }}
                    </span>
                    <span class="screening-task-content-task-select-question-type-selected-tags-count">{{
                        selectedTagsCount
                        }}</span>
                </div>
            </div>
            <screening-radio-button class="screening-task-content-task-select-needlogin" :title="needLoginTitle"
                :addTaskVisible="addTaskVisible" :is-need-login="isNeedLogin" v-model="needLoginRadio"
                :isSelect="isNeedLogin" :tooltipContent="loginTip" @input="onNeedLoginRadioChange"
                :header="header"></screening-radio-button>
        </div>
        <div class="screening-task-content-radio-button1">
            <div class="screening-task-content-radio-button1-blank">
                <screening-radio-button class="screening-task-content-radio-button1-blank-createuser"
                    :title="createUserTitle" :is-need-login="isNeedLogin" v-model="createUserRadio"
                    :isSelect="isCreateUser" :addTaskVisible="addTaskVisible" @input="onCreateUserRadioChange"
                    :tooltipContent="creteUserTip" :header="header" />
            </div>

            <screening-radio-button class="screening-task-content-radio-button1-resubmit " :title="reSubmitTitle"
                :is-need-login="isNeedLogin" :is-resumit="isResubmit" v-model="resubmitRadio" :isSelect="isResubmit"
                :is-resubmit="isResubmit" :addTaskVisible="addTaskVisible" @input="onResubmitRadioChange"
                :tooltipContent="resubmitTip" :header="header" />
        </div>
        <div class="screening-task-content-radio-button2">
            <div class="screening-task-content-radio-button2-blank">
                <screening-radio-button class="screening-task-content-radio-button2-displayresult"
                    :title="displayResultTitle" :is-need-login="isNeedLogin" v-model="displayResultRadio"
                    :isSelect="this.isDisplayResult" :addTaskVisible="addTaskVisible"
                    @input="onDisplayResultRadioChange" :tooltipContent="displayResultTip" :header="header" />
            </div>
            <screening-radio-button class="screening-task-content-radio-button2-modifiable" :title="modifiableTitle"
                :isSelect="this.isModifiable" :is-need-login="isNeedLogin" :is-resubmit="isResubmit"
                v-model="modifiableRadio" :tooltipContent="modifyTip" :addTaskVisible="addTaskVisible"
                @input="onModifiableRadioChange" :header="header" />
        </div>
        <div class="screening-task-content-input">
            <div class="screening-task-content-input-title">任务描述：</div>
            <textarea class="screening-task-content-input-input" v-model="taskDescribe"
                placeholder="请输入筛查任务介绍，如知情同意书内容，任务介绍将在用户开始测评前进行展示……"></textarea>
        </div>
    </div>
</template>

<script>
import { Input } from "element-ui";
import { DatePicker } from "element-ui";
import { Select } from "element-ui";
import { Option } from "element-ui";
import { Tag } from "element-ui";
import { DateTool } from "@js/date-tool.js";
import ScreeningRadioButton from "@c/screening-questionnaire/screening-dialog/screening-radio-button.vue"

export default {
    props: {
        header: String,
        addTaskVisible: Boolean,
        dialogEvent: Number,
        taskID: Number,
        taskData: Object,
        taskHospitalID: Number,

    },
    components: {
        elInput: Input,
        elDatePicker: DatePicker,
        elSelect: Select,
        elOption: Option,
        elTag: Tag,
        DateTool,
        ScreeningRadioButton,
    },



    data() {
        return {
            loginTip: "需要登陆：必须使用真实手机号登陆且补全信息才能测评\n不需登陆：匿名测评，仅记录基本信息，无法识别用户",
            creteUserTip: "是否自动在用户列表创建该用户\n需要登录为是才可启用",
            resubmitTip: "当用户使用手机号登录时，同一个手机号可以多次提交\n并保留多次提交记录，仅当需要登录为是时才可改为是",
            displayResultTip: "测评完成是否向用户展示测评得分、结果、建议等内容\n当需要登录为否时，必须展示结果",
            modifyTip: "当用户使用手机号登录时，是否可以修改自己已经提交的内容\n重复提交为否时才可配置",
            visibility: this.dialogVisible,
            options: [],
            selectedValues: [],
            selectedTags: '',
            selectedTagsCount: '',
            selectVisible: false, // 下拉框是否可见
            modifiableTitle: '可以修改：',
            displayResultTitle: '展示结果：',
            reSubmitTitle: '重复提交：',
            createUserTitle: '创建用户：',
            needLoginTitle: '需要登录：',
            textarea: '',
            startDate: '',
            endDate: '',
            taskName: '',
            needLoginRadio: '',
            isNeedLogin: 2,//需要登录
            createUserRadio: '',
            isCreateUser: 2,//创建用户
            resubmitRadio: '',
            isResubmit: 2,//重复提交
            displayResultRadio: '',
            isDisplayResult: 2,//展示结果
            modifiableRadio: '',
            isModifiable: 2,//可以修改
            taskDescribe: '',//任务描述
            accessmentType: [4, 5, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],//问卷类型
            addTaskCount: 0,
            taskid: this.taskID,
            taskContent: null,

        }
    },
    methods: {
        onStartDateClick() {
            this.$nextTick(() => {
                this.$refs.startDate.$refs.input.blur();
            });
        },
        onNeedLoginRadioChange(value) {

            if (value == '否') {
                this.isNeedLogin = 0
            } else if (value == '是') {

                this.isNeedLogin = 1
            } else {
                this.isNeedLogin = 2
            }
        },
        onCreateUserRadioChange(value) {
            // 处理创建用户的逻辑
            if (value == '否') {
                this.isCreateUser = 0
            } else if (value == '是') {
                this.isCreateUser = 1
            } else {
                this.isCreateUser = 2
            }
        },
        onResubmitRadioChange(value) {

            if (value == '否') {
                this.isResubmit = 0
            } else if (value == '是') {
                this.isResubmit = 1
            } else {
                this.isResubmit = 2
            }

        },
        onDisplayResultRadioChange(value) {

            if (value == '否') {
                this.isDisplayResult = 0
            } else if (value == '是') {
                this.isDisplayResult = 1
            } else {
                this.isDisplayResult = 2
            }
            // 处理创建用户的逻辑
        },
        onModifiableRadioChange(value) {

            if (value == '否') {
                this.isModifiable = 0
            } else if (value == '是') {
                this.isModifiable = 1
            } else {
                this.isModifiable = 2
            }
            // 处理创建用户的逻辑
        },
        updateSelectedTags(value) {

            this.selectedTags = this.selectedValues.map(value => {
                const option = this.options.find(o => o.value === value);

                return option ? option.label : '';
            }).join('、');
            if (this.selectedValues.length == 0) {
                this.selectedTagsCount = ''
            } else {
                this.selectedTagsCount = '共' + this.selectedValues.length + '个'
            }



        },
        removeTag(value) {

            this.selectedValues = this.selectedValues.filter(value => {
                const option = this.options.find(o => o.value === value);
                return option ? option.label !== tag : true;
            });
            if (this.selectedValues.length == 0) {
                this.selectedTagsCount = ''
            } else {
                this.selectedTagsCount = '共' + this.selectedValues.length + '个'
            }


        },


        handleSelectClick() {

            if (this.selectVisible) {
                // 下拉框未弹出，打开下拉框
                this.$refs.select.$el.click()


            } else {
                // 下拉框已经弹出，关闭下拉框
                this.$refs.select.handleClose()


            }

            this.selectVisible = !this.selectVisible


        },
        handleVisibleChange(visible) {
            this.selectVisible = visible
        },


        async accessmentListRequest() {
            const params = {
                "hospitalid": this.hospitalID,
                "type": this.accessmentType
            };
            try {
                const response = await this.$api.assessemntList(params);
                for (const accessment of response) {
                    const option = {
                        label: accessment.title,
                        value: accessment.type
                    }
                    this.options.push(option);
                }

            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        async addTask() {
            var hospitalId = null
            if (this.header === '编辑任务') {
                hospitalId = this.taskHospitalID
            } else {
                hospitalId = this.hospitalID
                if (this.hospitalID == null) {
                    this.$toast.showRed('请先选择机构');
                    return;
                }
            }


            // 判断 login、resubmit、shows、modify 和 create_user 是否符合要求
            if (this.isNeedLogin === 2 || this.isResubmit === 2 || this.isDisplayResult === 2 || this.isModifiable === 2 || this.isCreateUser === 2) {
                this.$toast.showRed('*号选项为必填项');
                return;
            }

            // 判断 type、name、startDate 和 endDate 是否符合要求
            if (!this.selectedValues || this.selectedValues.length === 0 || !this.taskName || !this.startDate || !this.endDate) {
                this.$toast.showRed('*号选项为必填项');
                return;
            }

            var startTime = null
            var endTime = null
            const chinaStandardTimeRegex = /^[\w\s]+\s\w+\s\d{2}\s\d{4}\s\d{2}:\d{2}:\d{2}\s(GMT)[+-]\d{4}\s\(中国标准时间\)$/;
            if (chinaStandardTimeRegex.test(this.startDate)) {
                const startYear = this.startDate.getFullYear();
                const startMonth = String(this.startDate.getMonth() + 1).padStart(2, "0");
                const startDay = String(this.startDate.getDate()).padStart(2, "0");
                const startFormattedDate = `${startYear}-${startMonth}-${startDay}` + " 00:00:00";
                startTime = startFormattedDate
            } else {
                startTime = this.startDate
            }

            if (chinaStandardTimeRegex.test(this.endDate)) {
                const endYear = this.endDate.getFullYear();
                const endMonth = String(this.endDate.getMonth() + 1).padStart(2, "0");
                const endDay = String(this.endDate.getDate()).padStart(2, "0");
                const endFormattedDate = `${endYear}-${endMonth}-${endDay}` + " 23:59:00";
                endTime = endFormattedDate
            } else {
                endTime = this.endDate
            }





            const startTimeStamp = new Date(this.startDate).getTime()
            const endTimeStamp = new Date(this.endDate).getTime()


            if (endTimeStamp < startTimeStamp) {
                this.$toast.showRed('结束时间不得大于起始时间');
                return;
            }

            const start = startTimeStamp / 1000
            const end = endTimeStamp / 1000 + 86395//加这个后面的时间到23:59:55

            const today = new Date();
            today.setHours(0, 0, 0, 0); // 设置为今天的零点
            const todyTimestamp = today.getTime() / 1000;
            if (end < todyTimestamp) {
                this.$toast.showRed('结束时间不得小于今天');
                return;
            }
            const type = this.selectedValues.join(",")





            const params = {
                type: type,
                name: this.taskName,
                start: startTime,
                end: endTime,
                login: this.isNeedLogin,
                resubmit: this.isResubmit,
                shows: this.isDisplayResult,
                modify: this.isModifiable,
                createUser: this.isCreateUser,
                desc: this.taskDescribe,
                hospitalId: hospitalId,

            };

            console.log("params", params);
            if (typeof this.taskID !== 'undefined') {
                params.id = this.taskID;
            }

            try {
                const response = await this.$api.addTask(params);
                this.addTaskCount += 1
                if (this.header === '编辑任务') {
                    this.$toast.showGreen('编辑任务成功');
                    this.$emit('edit', this.addTaskCount)
                } else {
                    this.$toast.showGreen('新增任务成功');
                    this.$emit('add', this.addTaskCount)
                }


                this.$emit('close', false);

            } catch (error) {
                this.$toast.showRed(error);
            }
        },
    },
    computed: {
        hospitalID: function () {
            return this.$store.state.selectedHospital.hospitalid;
        },

    },
    mounted() {
        // 监听 el-select 的 visible-change 事件
        this.taskContent = this.taskData

        this.$refs.select.$on('visible-change', this.handleVisibleChange)
        this.accessmentListRequest()

    },
    beforeDestroy() {

        this.$refs.select.$el.removeEventListener('click', this.handleVisibleChange)

        this.$refs.select = null
        this.handleVisibleChange = null
    },
    watch: {
        addTaskVisible(newValue) {

            if (newValue === false) {
                this.isResubmit = 2
                setTimeout(() => {
                    this.taskName = "";//清空任务名称
                    //清空问卷类型
                    this.selectedTags = '';
                    this.selectedTagsCount = '';
                    this.selectedValues = [];

                    //清空起止时间
                    this.startDate = '';
                    this.endDate = '';
                    //清空任务描述
                    this.taskDescribe = '';



                }, 100);
            } else {
                if (this.header === '编辑任务') {
                    this.selectedValues = []; // 清空 selectedValues 数组
                    this.taskContent.assessment.forEach(item => {
                        this.selectedValues.push(item.type);
                        this.selectedTags = this.taskContent.assessment.map(item => item.title).join('、');

                    });
                    this.selectedTagsCount = '共' + this.selectedValues.length + '个'

                    console.log("编辑", this.taskContent)
                    this.taskName = this.taskContent.name
                    this.isNeedLogin = this.taskContent.login
                    this.isCreateUser = this.taskContent.createUser
                    this.isResubmit = this.taskContent.resubmit
                    this.isDisplayResult = this.taskContent.shows
                    this.isModifiable = this.taskContent.modify
                    // this.startDate = DateTool.timestampToStr(this.taskContent.start, "Y/M/D");
                    // this.endDate = DateTool.timestampToStr(this.taskContent.end, "Y/M/D");
                    this.startDate = this.taskContent.start;
                    this.endDate = this.taskContent.end;
                    this.taskDescribe = this.taskContent.desc

                }
            }
        },
        dialogEvent(newValue) {

            this.addTask()
        },

        taskContent(data) {


            if (typeof data !== 'undefined') {

                this.selectedValues = []; // 清空 selectedValues 数组
                data.assessment.forEach(item => {
                    this.selectedValues.push(item.type);
                    this.selectedTags = data.assessment.map(item => item.title).join('、');

                });
                this.selectedTagsCount = '共' + this.selectedValues.length + '个'

                this.taskName = data.name
                this.isNeedLogin = data.login
                this.isCreateUser = data.createUser
                this.isResubmit = data.resubmit
                this.isDisplayResult = data.shows
                this.isModifiable = data.modify
                this.startDate = data.start
                this.endDate = data.end
                this.taskDescribe = data.desc
            }

        },
        taskData(data) {

            if (typeof data !== 'undefined') {
                console.log("taskData,", data);
                this.taskContent = data
            }
        }
    },

}
</script>

<style lang="scss" scoped>
@import "@css/style.scss";

.screening-task-content {
    width: 100%;
    height: 100%;
    color: #000000;
    font-size: rem(14);
    font-family: "microsoft-yahei";


    &-task-name-date {
        padding: rem(20) rem(20) rem(0) rem(20);
        display: flex;
        align-items: center;
        position: relative;

        &-task-symbol {
            color: #F45C50;

        }

        &-task-input {
            width: 37%;
            min-width: rem(140);

            ::v-deep .el-input__inner {
                height: rem(32);
                color: #000000;
                font-size: rem(14);

            }

            ::v-deep .el-input__placeholder {
                color: #C1C1C1;
            }
        }

        &-date {
            position: absolute;
            margin-left: 50%;

            &-symbol {
                color: #F45C50;


            }

            &-datepicker {
                cursor: pointer;
                width: rem(140);
                min-width: rem(140);

                ::v-deep .el-input__inner {
                    height: rem(32);
                    left: rem(15);
                    color: #000000;
                    font-size: rem(14);
                    cursor: pointer;
                }


                ::v-deep .el-select__tags {
                    font-size: 0px;

                }

                ::v-deep .el-input__prefix {
                    width: rem(20);
                    height: rem(20);
                    left: rem(114);
                    top: rem(6);
                    background: url('~@/assets/imgs/screening-calendar.png');
                }

                ::v-deep .el-input__icon::before {
                    // content: "\e78e";
                    content: "";

                }

                ::v-deep .el-input__suffix-inner {
                    display: none;
                }

                ::v-deep .el-input__inner {

                    &::placeholder {
                        color: #C1C1C1;
                    }

                    padding-left: rem(12);
                    padding-right: 12px;
                }



                ::v-deep .el-input__suufix {
                    width: rem(20);
                }

            }

            &-blank {
                margin-left: rem(10);
                margin-right: rem(10);
            }
        }








    }


    &-task-select {
        padding: rem(20) rem(20) rem(0) rem(20);
        display: flex;
        align-items: center;
        position: relative;

        &-needlogin {
            position: absolute;
            margin-left: 50%;
        }

        &-question-type {
            display: flex;
            width: 37%;
            align-items: center;
            position: relative;

            &-selected-tags {
                position: absolute;
                padding-left: rem(12);
                font-size: rem(14);
                color: #000000;
                max-width: 100%;
                display: flex;
                cursor: pointer;


                &-content {
                    max-width: 76.5%;
                    min-width: 20%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }



            }

            &-select {
                min-width: rem(140);

                width: 100%;

                ::v-deep .el-select__tags {
                    display: none;
                }

                ::v-deep .el-select-dropdown__item {
                    font-size: rem(14);
                    box-sizing: border-box;
                    padding-left: rem(20);
                    color: #000000;
                }

                ::v-deep .el-tag__close {
                    display: none;
                }

                ::v-deep .el-select__tags-text {
                    color: #000000;
                }



                ::v-deep .el-input__inner {
                    height: rem(32);
                    left: rem(15);

                }

                ::v-deep .el-input__suffix {
                    right: rem(6);
                }

                ::v-deep .el-select__caret::before {
                    content: "";
                    background: url('~@/assets/imgs/screening-arrow-up.png') center center no-repeat;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                }
            }
        }

    }

    &-radio-button1 {
        padding: rem(20) rem(20) rem(0) rem(20);
        display: flex;
        align-items: center;
        position: relative;

        &-blank {
            width: 43.8%;
        }

        &-resubmit {
            position: absolute;
            margin-left: 50%;
        }

    }

    &-radio-button2 {
        padding: rem(20) rem(20) rem(0) rem(20);
        display: flex;
        align-items: center;
        position: relative;

        &-blank {
            width: 43.8%;
        }

        &-modifiable {
            position: absolute;
            margin-left: 50%;
        }
    }

    &-input {
        padding: rem(20) rem(20) rem(40) rem(20);
        display: flex;

        &-title {
            margin-left: rem(7);

        }

        &-input {
            width: 79%;
            height: rem(280);
            resize: none;
            padding: rem(14);
            font-size: 16px;
            font-weight: 500;
            background: #F7F7F7;
            border: none;
            min-height: rem(28);


        }

        &-input:focus {
            outline: 1px solid #409eff;
        }

    }


}
</style>