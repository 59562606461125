<template>
    <div class="screening-dialog-footer">
        <div class="screening-dialog-footer-line"></div>
        <div class="screening-dialog-footer-content">
            <button class="screening-dialog-footer-content-button1" @click="onClose">取消</button>
            <button :class="button2Class" @click="onButtonClick">{{button2Content}}</button>

        </div>

    </div>
</template>

<script>

export default {
    props: {
        taskType: String,
    },
    computed: {
        button2Class() {
            if (this.taskType === "delete") {
                return "screening-dialog-footer-content-button2-delete";
            } else {
                return "screening-dialog-footer-content-button2-end";
            }
        },
        button2Content() {
            if (this.taskType === "end") {
                return "终止";
            } else if (this.taskType === "delete") {
                return "删除";
            } else if(this.taskType == "edit") {
                return "编辑";
            }else {
                return "新增";
            }
        }
    },
    data() {
        return {
            addCount:0,
        }
    },
    methods: {
        onClose: function () {
            this.$emit('close', false);
        },
        onButtonClick:function(){
            this.addCount += 1
             
            this.$emit('event', this.addCount);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@css/style.scss";

.screening-dialog-footer {
    &-line {
        width: calc(100% - rem(2));
        border: rem(1) solid #E8E8E8;
        opacity: 1;
    }

    &-content {
        width: 100%;
        height: rem(56);
        display: flex;
        align-items: center;
        justify-content: flex-end;
    
        &-button1 {
            
            margin-right: rem(10);
            width: rem(65);
            height: rem(32);
            border-radius: rem(2);
            border: rem(1) solid rgba(0,0,0,0.15);
            color: black;
            font-size: rem(14);
            background-color: #fff;
            transition: all 0.3s ease;

           

            &:active {
                background-color: #cccccc;
                
            }
        }
      
        &-button2-end {
            margin-right:rem(16) ;
            width: rem(65);
            height: rem(32);
            border-radius: rem(2);
            border: rem(1) solid #325FAC;
            color: white;
            font-size: rem(14);
            background-color: #325FAC;
            transition: all 0.3s ease;
            &:active {
                background-color: #0B3AAC;
            }
        }

        &-button2-delete {
            margin-right:rem(16) ;
            width: rem(65);
            height: rem(32);
            border-radius: rem(2);
            border: rem(1) solid #F45C50;
            color: white;
            font-size: rem(14);
            background-color: #F45C50;
            transition: all 0.3s ease;
            &:active {
                background-color: #E04D28;
            }
        }
    }
}
</style>