<template>
  <div class="screening-check-result-dialog">
    <dialog-header
      class="screening-check-result-dialog-header"
      v-model="headerTitle"
      @close="onDialogClose"
    ></dialog-header>
    <div class="screening-check-result-dialog-list">
      <div class="screening-check-result-dialog-list-title">
        <span style="flex: 1">姓名</span>
        <span style="flex: 2">手机号</span>
        <span style="flex: 6">测评得分/结果</span>
        <span style="flex: 2">时间</span>
        <span style="flex: 1">操作</span>
      </div>
      <div class="screening-check-result-dialog-list-content">
        <ul class="screening-check-result-dialog-list-content-list">
          <li v-for="(report, index) in reportList" :key="index">
            <div class="screening-report-list-item">
              <div style="flex: 1">
                {{ report.name }}
              </div>
              <div style="flex: 2">
                {{ report.phone }}
              </div>
              <div style="flex: 6">
                {{ report.score }}
              </div>
              <div style="flex: 2">
                {{ report.time }}
              </div>
              <div
                style="flex: 1; cursor: pointer; color: #325fac"
                @click="onCheck(report.id)"
              >
                查看
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="screening-check-result-dialog-bottom">
      <div class="screening-check-result-dialog-bottom-pagination">
        <pagination
          v-show="total != 0"
          :total="total"
          :pageSize="pageSize"
          :current-page="currentPage"
          @pagechange="pagechange"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.screening-check-result-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  min-width: rem(600);
  border-radius: 2px;

  &-header {
    width: 100%;
    height: rem(57);
  }

  &-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(20);

    &-title {
      background: #325fac;
      color: white;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      border-radius: 5px;
      width: 100%;
      height: 36px;
      display: flex;
      align-items: center;
    }

    &-content {
      width: 100%;
      height: 459px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &-bottom {
    margin-bottom: rem(40);
  }
}

.screening-report-list-item {
  height: rem(40);
  width: 100%;
  font-size: rem(14);
  text-align: center;
  color: black;
  display: flex;
  align-items: center;
}
</style>

<script>
import { DateTool } from "@js/date-tool.js";
import Pagination from "@c/common/pagination.vue";
import dialogHeader from "@c/screening-questionnaire/screening-dialog/screening-dialog-header.vue";
export default {
  props: {
    value: String,
    taskID: Number,
    count: Number,
  },
  components: {
    dialogHeader,
    Pagination,
    DateTool,
  },
  data() {
    return {
      headerTitle: this.value,
      dialogVisible: this.visibility,
      reportList: [],
      pageSize: 10, // 每页显示条数
      currentPage: 1, // 当前的页数
      total: undefined, // 显示总条数
      pages: 1, //总页数
    };
  },
  methods: {
    onDialogClose(value) {
      this.$emit("close", value);
    },

    onCheck(id) {
      let routeData = this.$router.resolve({
        path: "/query-result",
        query: {
          id
        },
      });
      window.open(routeData.href, "_blank");
    },

    async reportListRequest() {
      const params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        taskId: this.taskID,
      };
      try {
        const response = await this.$api.assessmentResultList(params);

        this.total = response.total;
        this.reportList = [];

        for (const reportItem of response.list) {
          var date = reportItem.gmtCreate.substring(0, 16);
          const item = {
            id: reportItem.id,
            name: reportItem.trueName,
            score:
              reportItem.total !== null
                ? reportItem.total + "分, " + reportItem.result
                : "--" + "分, " + reportItem.result,
            time: date,
            phone: reportItem.mobile,
          };
          if (reportItem.result.length > 58) {
            item.score =
              reportItem.total + "分," + reportItem.result.slice(0, 58) + "...";
          }
          this.reportList.push(item);
        }
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    //pagination
    pagechange: function (currentPage) {
      this.currentPage = currentPage;
      this.reportListRequest();
    },
  },
  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  watch: {
    taskID(newVal) {
      // this.reportListRequest();
    },
    count(newVal) {
      this.currentPage = 1;
      this.reportListRequest();
    },
  },
  mounted() {
    this.reportListRequest();
  },
};
</script>
