<template>
    <div class="screening-edit-task-content">
      <div class="screening-edit-task-content-1">{{taskContent1}}</div>
      <div class="screening-edit-task-content-2">{{taskContent2}}</div>
    </div>
  </template>

<script>
export default {
    props: {
        taskType: String,
        id:Number,
    },
    data() {
        return {
            
        }
    },
    computed: {
        taskContent1() {
            if (this.taskType === "delete") {
                return "筛查任务已结束，无法编辑";
            } else if (this.taskType === "end") {
                return "筛查任务已开始，无法编辑";
            } else {
                return "";
            }
        },
        taskContent2() {
            if (this.taskType === "delete") {
                return "是否要删除任务？";
            } else if (this.taskType === "end") {
                return "是否要终止任务？";
            } else {
                return "";
            }
        }
    },
   
}

</script>
<style lang="scss" scoped>
@import "@css/style.scss";

.screening-edit-task-content {
    white-space: pre-wrap;
    font-size: rem(14);
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: rem(20) rem(24) rem(125) rem(24);
    &-2{
        margin-top: rem(7);
    }
}
</style>
