<template>
    <div class="screening-qr-dialog">
        <dialog-header class="screening-qr-dialog-header" v-model="headerTitle" @close="onDialogClose"></dialog-header>
        <div class="screening-qr-dialog-content">
            <vue-qr ref="qr" id="qrcode" class="screening-qr-dialog-content-qr" :logoSrc="imageUrl" :text="url"
                :size="200"></vue-qr>
            <div class="screening-qr-dialog-content-download" @click="downLoadQR('qr')">点击下载</div>
        </div>
    </div>
</template>
  
<script>
import dialogHeader from "@c/screening-questionnaire/screening-dialog/screening-dialog-header.vue";
import { computed } from "vue";
import vueQr from 'vue-qr';
export default {
    props: {
        value: String,
        qrURL: String,
        name:String
    },
    components: {
        dialogHeader,
        vueQr,
    },
    data() {
        return {
            headerTitle: this.value,
            imageUrl: require("@imgs/logo-bajie-small-28.png"),//icon路径
            qrId: '',
            url: '',
            taskName:'',
        };
    },
    methods: {

        onDialogClose(value) {
            this.$emit('close', value);
        },
        downLoadQR() {
            const iconUrl = this.$refs.qr.$el.src
            const a = document.createElement('a')
            const event = new MouseEvent('click')
            a.download = this.taskName+'二维码.png'
            a.href = iconUrl
            a.dispatchEvent(event)
        },

    },
    watch: {
        qrURL(newVal) {
            this.url = newVal
        },
        name(newVal){
            this.taskName = newVal
        }
    },
    mounted() {
        this.url = this.qrURL
        console.log("qrrul",this.url);
        this.taskName = this.name
    },

}
</script>
   
<style lang="scss" scoped>
@import "@css/style.scss";

.screening-qr-dialog {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    &-header {
        width: 100%;
        height: rem(57);
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #325FAC;

        &-download {
            cursor: pointer;
        }
    }




}
</style>