<template>
  <div class="screening-add-task-dialog-content">
    <dialog-header class="screening-add-task-dialog-content-header" v-model="headerTitle"
      @close="onDialogClose"></dialog-header>
    <div class="screening-add-task-dialog-content-task-wrapper">
      <task-content class="screening-add-task-dialog-content-task-content" :header="this.headerTitle" :addTaskVisible="visibility" :dialogEvent="dialogEvent"  @close="onDialogClose" @add="onAddSucceed" @edit="onEditSucceed" :taskID="taskID" :taskData="taskData" :taskHospitalID="taskHospitalID"></task-content>
    </div>
    <dialog-footer class="screening-add-task-dialog-content-footer" @close="onDialogClose" @event="onDialogEvent" :taskType="taskType" ></dialog-footer>
  </div>
</template>

<script>
import dialogHeader from "@c/screening-questionnaire/screening-dialog/screening-dialog-header.vue"
import taskContent from "@c/screening-questionnaire/screening-dialog/screening-task-content.vue"
import dialogFooter from "@c/screening-questionnaire/screening-dialog/screening-dialog-footer.vue"
export default {
  props: {
    value: String,
    visibility:Boolean,
    event:Number,
    taskID:Number,
    taskType:String,
    taskData:Object,
    taskHospitalID:Number,
  },
  components: {
    dialogHeader,
    taskContent,
    dialogFooter,
  },
  data() {
    return {
      headerTitle: this.value,
      dialogEvent:this.event,
    };
  },
  methods: {

    onDialogClose(value) {
      this.$emit('close', value);
    },
    onDialogEvent(value){
     
      this.dialogEvent = value
    },

    onAddSucceed(value){
      this.$emit('add', value);
    },

    onEditSucceed(value){
      this.$emit('edit',value)
    }
  },

  watch:{
    taskHospitalID(val){
      console.log("addtaskhospitalid",val);
    }
  }
 

}
</script>
 
<style lang="scss" scoped>
@import "@css/style.scss";

.screening-add-task-dialog-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-header {
    width: 100%;
    height: rem(57);
  }
  &-task-content{
    width: 100%;
    height:  calc(100% - rem(114));;
  }
  &-footer{
    width: 100%;
    height:  rem(56);
  }
 
}</style>