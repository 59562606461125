<template>
    <div class="screening-edit-task-dialog-content">
      <dialog-header class="screening-edit-task-dialog-content-header" v-model="headerTitle"
        @close="onDialogClose"></dialog-header>
      <div class="screening-edit-task-dialog-content-task-wrapper">
        
        <task-content class="screening-eidt-task-dialog-content-task-content" :taskType="this.taskType"></task-content>
      </div>
      <dialog-footer class="screening-edit-task-dialog-content-footer" :taskType="taskType" @close="onDialogClose" @event="onDialogEvent"></dialog-footer>
    </div>
  </template>
  
  <script>
  import dialogHeader from "@c/screening-questionnaire/screening-dialog/screening-dialog-header.vue"
  import taskContent from "@c/screening-questionnaire/screening-dialog/screening-edit-task-content.vue"
  import dialogFooter from "@c/screening-questionnaire/screening-dialog/screening-dialog-footer.vue"
  export default {
    props: {
    value: String,
    taskType:String
  },
    components: {
      dialogHeader,
      taskContent,
      dialogFooter,
    },
    data() {
      return {
        headerTitle: this.value,
        dialogVisible: this.visibility,
      };
    },
    methods: {
      onDialogEvent(value){
        this.$emit('event', value);
    
    },
      onDialogClose(value) {
        this.$emit('close', value);
      },
    },
  
  }
  </script>
   
  <style lang="scss" scoped>
  @import "@css/style.scss";
  
  .screening-edit-task-dialog-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  
    &-header {
      width: 100%;
      height: rem(57);
    }
    &-task-content{
      width: 100%;
      height:  calc(100% - rem(114));;
    }
    &-footer{
      width: 100%;
      height:  rem(56);
    }
   
  }</style>