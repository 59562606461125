<template>
    <div class="screening-dialog-result">
        <dialog-header class="screening-dialog-result-header" v-model="headerTitle" @close="onDialogClose"></dialog-header>
        <div class="screening-dialog-result-content">
            <div>正在导出数据，请稍后……</div>
        </div>
    </div>
</template>
  
<script>
import dialogHeader from "@c/screening-questionnaire/screening-dialog/screening-dialog-header.vue";


export default {
    props: {
        value: String,
    },
    components: {
        dialogHeader,
    },
    data() {
        return {
            headerTitle: this.value,
        };
    },
    methods: {

        onDialogClose(value) {
           
            this.$emit('close', value);
        },


    },

}
</script>
   
<style lang="scss" scoped>
@import "@css/style.scss";

.screening-dialog-result {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    &-header {
        width: 100%;
        height: rem(57);
    }

    &-content {
        
  
        height:  calc(100% - rem(57));;
        padding: rem(20) rem(24) rem(20) rem(24);

        color: black;
        font-size: rem(14);

    }




}
</style>