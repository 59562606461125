<template>
    <div class="screening-radio-button">
        <div class="screening-radio-button-title">
            <span class="screening-radio-button-title-symbol">*</span>
            <span class="screening-radio-button-title-content">{{ title }}</span>

        </div>
        <el-radio-group class="screening-radio-button-group" v-model="innerValue" :disabled="isGroupDisabled">
            <el-radio-button class="screening-radio-button-group-button" label="是"></el-radio-button>
            <el-radio-button class="screening-radio-button-group-button" label="否"></el-radio-button>
        </el-radio-group>
        <el-tooltip class="item" effect="light" placement="right" :style="{ whiteSpace: 'pre-wrap' }">\
            <template #content>
                <div style="white-space: pre-wrap;">{{ tooltipContent }}</div>
            </template>
            <img class="screening-radio-button-prompt" src="@imgs/screening-radiao-button-prompt.png" alt="提示"
                @click="onClickPrompt">
        </el-tooltip>

    </div>
</template>

<script>
import { RadioGroup } from "element-ui";
import { RadioButton } from "element-ui";
import { Tooltip } from "element-ui";
import { watch } from "vue";
export default {
    props: {
        header: String,
        title: String,
        value: String,
        isNeedLogin: Number,
        isResubmit: Number,
        isModifiable: Number,
        addTaskVisible: Boolean,
        tooltipContent: String,
        isSelect: Number,
    },
    components: {

        elRadioGroup: RadioGroup,
        elRadioButton: RadioButton,
        elTooltip: Tooltip,

    },

    data() {
        return {
            radio: "1",
            realTitle: this.title,
            innerValue: this.value,
            isGroupDisabled: false,
            resubmit : this.isResubmit,
            modify : this.isModifiable,

        }
    },
    methods: {
        onClickPrompt() {

        },
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    watch: {
        innerValue(newValue) {
            this.$emit('input', newValue)
        },
        isNeedLogin(newValue) {
           
            if (newValue === 0) {
                if (this.title === '需要登录：') {
                    this.innerValue = '否'
                } else if (this.title === '创建用户：') {
                    this.innerValue = '否'
                    this.isGroupDisabled = true
                } else if (this.title === '重复提交：') {
                    this.innerValue = '否'
                    this.isGroupDisabled = true
                } else if (this.title === '展示结果：') {
                    this.innerValue = '是'
                    this.isGroupDisabled = true
                } else if (this.title === '可以修改：') {
                    this.innerValue = '否'
                    this.isGroupDisabled = true
                }

            } else if (newValue === 1) {

                if (this.title === '创建用户：') {
                    this.isGroupDisabled = false;
                } else if (this.title === '重复提交：') {
                    this.isGroupDisabled = false;
                } else if (this.title === '展示结果：') {
                    this.isGroupDisabled = false;
                } else if (this.title === '可以修改：') {
                    this.isGroupDisabled = false;
                }

            }
        },
        
        isResubmit(newValue) {
           
            if (newValue === 1) {
                
                if (this.title === '可以修改：') {
                    this.isGroupDisabled = true;
                    this.innerValue = '否';
                  
               
                } else {
                   
                    this.isGroupDisabled = false;
                }
            } else {
              
                if (this.isNeedLogin === 1) {
                    if (this.title === '可以修改：') {
                        if (this.header !== '编辑任务') {
                            this.isGroupDisabled = false;
                        } else {
                            this.isGroupDisabled = false;
                        }
                    } else {
                        if (this.header !== '编辑任务') {
                            this.isGroupDisabled = false;
                        }
                    }
                } else if(this.isNeedLogin === 0){

                    this.isGroupDisabled = true;
                    this.innerValue = '否'
                }else {
                    this.isGroupDisabled = false;
                }


            }

        },
        isSelect(newValue) {

            if (this.header === '编辑任务') {
                const titleMap = {
                    '需要登录：': 'innerValue',
                    '创建用户：': 'innerValue',
                    '重复提交：': 'innerValue',
                    '展示结果：': 'innerValue',
                    '可以修改：': 'innerValue',
                };

                const valueMap = {
                    1: '是',
                    0: '否',
                };
         

                if (titleMap.hasOwnProperty(this.title)) {
                    console.log("isSelect",this.title+':'+newValue);
                    this[titleMap[this.title]] = valueMap[newValue] || '';
                }
            }

        },

        addTaskVisible(newValue) {

            if (this.header === '编辑任务') {
                if (newValue === false) {
                    this.innerValue = ''
                } else {

                    const valueMap = {
                        "需要登录：": ['否', '是'],
                        "创建用户：": ['否', '是'],
                        "重复提交：": ['否', '是'],
                        "展示结果：": ['否', '是'],
                        "可以修改：": ['否', '是']
                    };

                    if (this.title in valueMap) {
                        this.innerValue = valueMap[this.title][this.isSelect];
                    }
                }

            } else {
              
                // this.innerValue = ''
                this.isGroupDisabled = false
                this.innerValue = ''
            }

        },


    },





}
</script>

<style lang="scss" scoped>
@import "@css/style.scss";

.screening-radio-button {
    display: flex;
    align-items: center;

    &-prompt {
        margin-left: rem(10);
        cursor: pointer;
    }

    &-title {
        &-symbol {
            color: #F45C50;
        }
    }

    &-group {
        &-button {

            ::v-deep .el-radio-button__inner:hover {
                color: #325FAC;
            }

            ::v-deep .el-radio-button__inner {
                font-size: rem(14);
                padding: rem(10) rem(10) rem(10) rem(10);
                height: rem(34);
                width: rem(60);
                color: #000;
                background: #fff;
            }

            ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background-color: #325FAC;
                border-color: #325FAC;
                color: #fff;
                border-color: #325FAC;
                box-shadow: -1px 0 0 0 #325FAC
            }


        }
    }
}
</style>