import { render, staticRenderFns } from "./screening-edit-task-content.vue?vue&type=template&id=64c4c0e4&scoped=true"
import script from "./screening-edit-task-content.vue?vue&type=script&lang=js"
export * from "./screening-edit-task-content.vue?vue&type=script&lang=js"
import style0 from "./screening-edit-task-content.vue?vue&type=style&index=0&id=64c4c0e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c4c0e4",
  null
  
)

export default component.exports