<template>
    <div class="screening-task">
        <div class="screening-task-top">
            <p class="screening-task-top-title">筛查任务</p>
            <button class="screening-task-top-add-task" @click="onAddTask">
                新增任务
            </button>
        </div>
        <div class="screening-task-list">
            <ul>
                <li v-for="(screeing, index) in screeingList" :key="index">
                    <div class="screening-task-list-item" :class="{ select: index === selectedIndex }"
                        @click="choiceItem(screeing.id, index)">
                        <div class="screening-task-list-title-state">
                            <div class="screening-task-list-title">{{ screeing.name }}</div>
                            <div class="screening-task-list-state">{{ screeing.state }}</div>
                        </div>
                        <div class="screening-task-list-count-time">
                            <div class="screening-task-list-count">{{ screeing.num }}人已测</div>
                            <div class="screening-task-list-time">结束：{{ screeing.endTime }}</div>
                        </div>
                    </div>

                </li>
            </ul>
        </div>
        <div class="screening-task-bottom">
            <div class="screening-task-bottom-pagination">
                <pagination v-show="total != 0" :total="total" :pageSize="pageSize" :current-page="currentPage"
                    @pagechange="pagechange"></pagination>
            </div>
        </div>
        <div class="screening-task-dialog">

            <el-dialog class="screening-task-dialog-dialog" :visible.sync="dialogVisible" width="61%" :showClose=false
                :padding="0">
                <div class="el-dialog-div">
                    <add-task-dialog class="el-dialog-div-add-task" v-model="headerTitle" :visibility="dialogVisible"
                        @close="onDialogClose" @add="onAddSucceed" :taskID="this.taskID"></add-task-dialog>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
import Pagination from "@c/common/pagination.vue";
import { Button } from "element-ui";
import { Dialog } from "element-ui";
import addTaskDialog from "@c/screening-questionnaire/screening-dialog/screening-add-task-dialog.vue"

export default {
    props: {
        editCount: Number,
        deleteCount: Number,
    },
    components: {
        Pagination,
        addTaskDialog,
        elButton: Button,
        elDialog: Dialog,
    },
    data() {
        return {
            selectID: null,//选中的index内容的id用来请求筛查详情
            selectedIndex: 0,//选中的list中index位置
            screeingList: [],
            total: undefined, // 显示总条数
            pageSize: 5, // 每页显示条数
            currentPage: 1, // 当前的页数
            dialogVisible: false,
            headerTitle: '',
            taskID: 0,
            selectIDType: '',
        };
    },
    created() {
        // 在created钩子函数中向任务列表中添加初始化数据
        this.selectIDType = ''
        this.screeningListRequest();
        this.editNum = this.editCount
    },
    methods: {
        sendDataToParent() {
            this.$emit('selectID-changed', this.selectID)
        },
        onAddTask() {
            if (this.hospitalID == null) {
                this.$toast.showRed('请先选择机构');
                return
            }
            this.headerTitle = '新增任务'
            this.dialogVisible = true;
        },
        onDialogClose(value) {
            this.dialogVisible = value;
        },
        onAddSucceed(value) {
            this.selectIDType = ''
            this.selectedIndex = 0;
            this.currentPage = 1;
            this.screeningListRequest();
        },
        choiceItem(id, index) {
            this.selectID = id;
            this.selectedIndex = index;
        },

        async screeningListRequest() {
            const params = {
                "pageNum": this.currentPage,
                "pageSize": this.pageSize,
                "hospitalId": this.hospitalID,
            };
            try {
                const response = await this.$api.assessmentTaskList(params);
                this.total = response.total;
                    this.screeingList = []
                    if (response.list.length > 0) {
                        


                        for (const screeningItem of response.list) {
                            var endDate = screeningItem.end.substring(0, 10);
                            var state = '未开始'
                            if (screeningItem.status === 1) {
                                state = '未开始'
                            } else if (screeningItem.status === 2) {
                                state = '进行中'
                            } else {
                                state = '已结束'
                            }

                            const item = {
                                name: screeningItem.name,
                                endTime: endDate,
                                num: screeningItem.num,
                                state: state,
                                id: screeningItem.id
                            };
                            this.screeingList.push(item);
                        }
                        if (this.selectIDType === '') {
                            this.selectID = response.list[0].id
                        }
                  
                    }else {
                        this.selectID = -1
                    }
            } catch (error) {
                this.$toast.showRed(error);
            }
        },
        //pagination
        pagechange: function (currentPage) {
            this.selectIDType = ''
            this.selectedIndex = 0;
            this.currentPage = currentPage;
            this.screeningListRequest();
        },

    },
    computed: {
        hospitalID: function () {
            return this.$store.state.selectedHospital.hospitalid;
        },

    },
    watch: {

        hospitalID: function () {
            this.selectIDType = ''
            this.selectedIndex = 0;
            this.currentPage = 1;
            this.screeningListRequest();
        },
        selectID: function (newVal, oldVal) {
            this.sendDataToParent()
            // 在这里可以进行对selectID的处理逻辑
        },
        editCount(newValue) {

            this.selectIDType = 'edit'
            this.screeningListRequest();
        },

        deleteCount(newValue) {
            this.selectIDType = ''
            this.selectedIndex = 0;
            this.currentPage = 1;
            this.screeningListRequest();
        }


    },
};

</script>

<style lang="scss" scoped>


.el-dialog-div {
    height: auto;
    // overflow: auto;

    &-add-task {
        width: 100%;
        height: 100%;
    }


}

.screening-task-dialog-dialog {
    ::v-deep .el-dialog__body {
        padding: 0px;
    }

    ::v-deep .el-dialog__header {
        display: none;
    }
}


.screening-task {
    background-color: white;
    width: 40%;
    min-height: rem(766);
    height: 100%;
    box-sizing: border-box;

    &-top {
        margin: rem(27) rem(20) rem(10) rem(36);
        font-size: rem(16);
        width: calc(100% - rem(36)-rem(20));
        height: rem(42);
        display: flex;

        justify-content: space-between;

        &-title {
            color: #325FAC;
            font-size: rem(14);
            font-family: "microsoft-yahei";
            border-bottom: rem(2) solid #325FAC;
            width: rem(100);
            height: rem(32);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-add-task {
            color: white;
            background-color: #325FAC;
            font-size: rem(14);
            font-family: "microsoft-yahei";
            width: rem(88);
            height: rem(32);
            border-radius: 4px;
            margin-left: rem(74);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-list {
        ul {
            margin-top: rem(30);

            li {

                margin-top: rem(20);
                height: rem(80);
                cursor: pointer;
                transition: background-color 0.2s;

                &:hover {
                    background-color: #f7f7f7;
                }
            }
        }


    }

    &-bottom {
        width: 100%;
        height: auto;
        margin-top: rem(90);
        display: flex;
        flex-direction: column;
        align-items: center;



        &-pagination {
            width: 100%;
            height: rem(55);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}

.screening-task-list-item.select {
    background-color: #f7f7f7;
}

.screening-task-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    // .select{
    //     background-color: red;
    // }
    .screening-task-list-title-state {
        margin: rem(12) rem(20) rem(0) rem(20);

        display: flex;
        font-weight: bold;
        align-items: center;
        width: 100%;

        .screening-task-list-title {
            max-width: rem(210);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: rem(18);
            color: #262626;

        }

        .screening-task-list-state {
            font-size: rem(18);
            color: #96ADDF;

            margin-left: auto;
            /* 状态右对齐 */
        }
    }

    .screening-task-list-count-time {
        margin: rem(14) rem(20) rem(20) rem(20);

        display: flex;
        flex-direction: row;
        // justify-content: flex-start;
        height: 100%;
        width: 100%;


        /* 添加间距 */

        .screening-task-list-count {
            font-size: rem(14);
            color: #888888;
            font-family: 'microsoft-yahei';
        }

        .screening-task-list-time {
            font-size: rem(12);
            color: #C1C1C1;
            font-family: 'microsoft-yahei';
            margin-left: auto;
            /* 时间右对齐 */
        }

        .screening-task-dialog-div {
            // height: rem(600); //如果高度过高，可用max-height
            overflow: auto;
        }

    }


}
</style>